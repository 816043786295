<template>
	<div>
		<please-login v-if="!loggedIn"></please-login>
		<v-sheet v-else
			flat
			color="lightest"
			class="pa-5 mw-survey rounded-lg"
		>
			<template v-if="survey">

				<survey-inner
					:id="surveyId"
					@save="save"
					@setUser="(v) => (participant = v)"
				></survey-inner>
			</template>
			<v-snackbar v-else color="primary" v-model="error" text>
				No survey found
			</v-snackbar>
		</v-sheet>
	</div>
</template>
<style lang="less"></style>
<script>

import PleaseLogin from "@c/ui/PleaseLogin"
import SurveyInner from "@c/survey/render/Survey.vue";
export default {
	name: "Consent",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			error: false,
			data: {},
			visibleQuestions: [],
			participant: false,
		};
	},
	components: {
		SurveyInner,
		PleaseLogin
	},
	methods: {
		redirect() {
			const self = this;
            self.$router.replace({
                name: "ThankYou",
                params: { content: "consentthankyou"},
            });
		},
		save(v){
			const self = this;
			self.$store.dispatch( "consents/insert", {
				appointment_participant: self.id, 
				survey: self.surveyId, 
				data: v.data
			}).then( () => {
				self.redirect();
			})
		}
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.data.id ? true : false;
		},
		appointmentParticipant() {
            return this.$store.state.appointmentParticipants.data[this.id];
		},
		appointmentId() {
            return this.appointmentParticipant ? this.appointmentParticipant.appointment : null;
		},
		appointment() {
            return this.$store.state.appointments.data[this.appointmentId];
		},
		projectId() {
            return this.appointment ? this.appointment.project : null;
		},
		project() {
            return this.$store.state.projects.data[this.projectId];
		},
		surveyId(){
			return this.project ? this.project.consent : null;
		},
        survey() {
            return this.$store.state.surveys.data[this.surveyId];
        },
	},
	watch: {
		loggedIn: {
			immediate: true, 
			handler(v){
				if( v ){
					this.$store.dispatch("appointmentParticipants/fetchById", this.id );
				}
			}
		},
		projectId: {
			immediate: true, 
			handler(v){
				if( v ){
					this.$store.dispatch("projects/fetchById", v );
				}
			}
		},
		surveyId: {
			immediate: true, 
			handler(v){
				if( v ){
					this.$store.dispatch("surveys/fetchById", v );
				}
			}
		}
	}
};
</script>
