<template>
    
    <v-card color="lightest" class="rounded-xl" flat>
				<v-card-text>
                    Please
					<a @click="$root.$emit('openLogin')">log in</a>
				</v-card-text>
			</v-card>

</template>



<script>
export default {
    name: "PleaseLogIn"
}
    </script>